import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll';


// Styles 
import { SeoTextHolderBlue } from "./SeoTextTwo.styles"

const SeoText2 = () => ( 
    <SeoTextHolderBlue>
        <h3>Vilken städtjänst behöver du?</h3><br/>
        <p>Gäller det fönsterputs, hemstädning, städning 
            av trapphus, kontorsstädning och entré och butiksstädning utifrån behov och krav, 
            så har vi gjort det enkelt för dig att hitta rätt städfirma i 
            Göteborg till rätt pris. Eller så har du flyttat och är ute efter 
            enbart flyttstäd. Allt du behöver göra är fylla i <a onClick={() => scrollTo('#offert')}> offertförfrågan </a>
            nedan och du får alla dina svar i din mail eller 
            via telefon från dina lokala städfirmor.<br/>
        </p>
    </SeoTextHolderBlue>
 
)

export default SeoText2