import styled from "styled-components"

export const SeoTextHolderBlue = styled.div`

background:#f9f9f9;
width:100%;
margin:0 auto;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
padding-top:50px;
padding-bottom:100px;

h2 {
    font-size:2rem;
    text-align:center;
    color:rgba(0,0,0,.7);
    position:relative;
    width:100%;
    :before {
        content:"";
        height:2px;
        width:80%;
        left:10%;
        background:${props => props.theme.colors.orange};
        position: absolute;
        bottom: -5px;
    }
}
p {
    text-align:center;
    color:rgba(0,0,0,.7);
    margin-top:0;
    font-size:1.2rem;
    width:80%;
    margin:0 auto;
}

@media screen and (min-width: 768px) {

h2 {
    font-size:2.2rem;
    :before {
        
        width:50%;
        left:25%;
        
    }
    
}

}
`