import React from "react"
import { graphql, useStaticQuery } from 'gatsby'

// Components
import Layout from 'components/Layout'
import SEO from "components/SEO"
import SeoText from "components/SeoText"
import SeoText2 from "components/SeoText2"
import HeroTwoSides from "components/HeroTwoSides"
import IconThree from "components/IconThree"
import OffertForm from "components/OffertForm"

const IndexPage = ({data}) => {


const schema = {
  
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Städfirmor Göteborg",
    "description": "Städfirmor Göteborg hjälper dig att hitta rätt städfirma i Göteborg. Fyll ien enkel formulär som skickas till samtliga städfirmor i Göteborg", 
    "department": [
      "Hemstädning Göteborg",
      "Städfirma Göteborg",
      "Hemstädning Göteborg",
      "Fönsterputsning Göteborg",
      "Företagsstädning Göteborg",
      "Flyttstädning Göteborg",
      "Trappstädning Göteborg",
      "Byggstädning Göteborg",
      "Golvvård Göteborg"
    ],
    "location": [
      "Göteborg", 
    ],
    "url": "https://stadfirmorgoteborg.se/",
    "logo": "https://stadfirmorgoteborg.se/static/261d75957584a7c9ad2791898a80f280/logo.png"
  
}

return(
  <Layout>
    <SEO title="Städfirmor Göteborg | Samtliga städfirmor i Göteborg" schemaMarkup={schema} />
    <HeroTwoSides /> 
    <SeoText />
    <IconThree />
    <OffertForm />
    <SeoText2 />

  </Layout>
) 
}
export default IndexPage

