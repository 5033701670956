import React from 'react'

// Styles 
import { SeoTextHolder } from "./SeoText.styles"

const SeoText = () => ( 
    <SeoTextHolder>
        <span>Om oss</span>
        <h2>Det är enkelt med Städfirmor Göteborg</h2>
        <p>I Göteborg finns det runt hundratals städfirmor som erbjuder städtjänster. 
            Det är inte lätt att hitta den som passar dig eller att byta till en bättre städfirma 
            än den du anlitar för tillfället. För att du ska kunna hitta rätt städfirma till rätt
            pris har vi på Städfirmor Göteborg skapat en enkel tjänst som underlättar processen. 
            Det är enkelt, fyll i en enkel ( och vi menar enkel och kort ) formulär nedan och vänta in 
            offerter från samtlig städfirmor från Göteborg som är intresserade att utföra tjänsten du söker.
        </p>
    </SeoTextHolder>
 
)

export default SeoText