import React from 'react'

// Styles
import { OffertHolder } from "./Offertform.styles"

const Offertform = () => (
    <OffertHolder>
        <h2 id="offert">Fyll i offertförfrågan</h2>
        <form 
        name="Stadoffert"
        method="POST"
        action="/success" 
        data-netlify-honeypot="bot-field"  
        data-netlify="true" >
            <input type="hidden" name="form-name" value="Stadoffert"/>
            <input type="hidden" name="subject" value="Offertförfrågan från Städfirmor Göteborg" />

                <div class="kund-info">
                    <div className="mina-uppgifter kund-uppgifter" >
                        <h3>Kontaktuppgifter</h3>

                        <div className="row-del">
                            <label>Privat<input type="checkbox" name="Privat" value="Ja Privat" /></label>
                            <label>Företag<input type="checkbox" name="Foretag" value="Ja Företag" /></label>
                            <label>Förmedlare<input type="checkbox" name="Formedlare" value="Ja Förmedlare" /></label>
                        </div>
                    
                        <div className="row-del del-tva">
                            <label class="nono">Namn<input placeholder="Namn" type="text" name="Namn" id="name" required/></label>
                            <label class="nono">Telefon<input placeholder="Telefon" type="number" name="Telefon" id="telefon" required/></label>
                            <label class="nono">Epost<input placeholder="Epost" type="text" name="Epost" id="email" required/></label>
                        </div>
                       <label class="nono">Komentar<textarea placeholder="Kommentar, datum, yta " name="Komentar" id="textarea" ></textarea></label> 
                    </div> {/* end mina-uppgifter */}
                <div className="mina-uppgifter tjanst-val" >
                    <h3>Önskad städtjänst</h3>

                    <div className="row-del">
                        <label>Hemstädning<input type="checkbox" name="Hemstadning?" value="Jag önskar Hemstädning" /></label>
                        <label>Fönsterputsning<input type="checkbox" name="Fonsterputsning?" value="Jag önskar Fönsterputsning" /></label>
                        <label>Företagsstädning<input type="checkbox" name="Foretagsstädning?" value="Jag önskar Företagsstädning" /></label>
                    </div>
                        
                    <div className="row-del del-tva">
                        <label>Flyttstädning<input type="checkbox" name="Flyttstadning?" value="Jag önskar Flyttstädning" /></label>
                        <label>Golvvård<input type="checkbox" name="Golvvard?" value="Jag önskar Golvvård" /></label>
                        <label>Trappstädning<input type="checkbox" name="Trappstadning?" value="Jag önskar Trappstädning" /></label>
                        <label>Byggstädning<input type="checkbox" name="Byggstadning?" value="Jag önskar Byggstädning" /></label>
                    </div>

                    <div className="gdpr">
                        <input type="checkbox" name="checkbox" required value="
                        Genom att jag har skickat detta meddelande samtycker jag till att 
                        ni tar del av de personuppgifter jag har valt att delge.
                        "/>
                        <label>
                        Städfirmor Göteborg lagrar bokningshistorik och personuppgifter som 
                        kunden lämnar och behandlar dessa uppgifter i enlighet med gällande 
                        GDPR (General Data Protection Regulation). Genom att skicka offertförfågan 
                        godkänner du användaravtalet.
                        </label>
                    </div>

                    <input className="button " type="submit" value="Skicka" />
                </div> {/* end mina-uppgifter */}
            </div>
        </form>
    </OffertHolder>

)

export default Offertform
